import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Door.css";
import { Headings } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Edit from "../../Assets/Edit.svg";
import { getDoors, deleteDoor } from "../../Store/Features/Door";
import { useDispatch, useSelector } from "react-redux";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import search from "../../Assets/search.svg";
import CustomModal from "../../Components/Modal/DoorModal";
import Inspection from "../../Components/Modal/Inspection";
import { Modal, Box, Button, Typography } from "@mui/material";

const Doors = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		value: doors,
		count,
	} = useSelector((state) => state.doors);
	const { user } = useSelector((state) => state.auth);
	const [searchQuery, setSearchQuery] = useState("");
	const [doorId, setDoorId] = useState();
	const matches = useMediaQuery("(max-width:769px)");
	const [open, setOpen] = useState(false);
	const [openInspection, setOpenIspection] = useState(false);
	const [error, setError] = useState();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleRowClick = (e) => {
		if (user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID) {
			navigate(
				`/sites/${e.row.data.floor?.siteId}/floor/${e.row.data?.floorId}/asset/${e.row.data?.id}/sensor/addSensor`
			);
		} else {
			navigate(
				`/sites/${e.row.data.floor?.siteId}/floor/${e.row.data?.floorId}/asset/${e.row.data?.id}`
			);
		}
	};

	// Filter the sites array based on the search query
	const filteredDoors = doors.filter((door) => {
		// Iterate through each field in the site object
		let each = false;
		if (
			user?.roleId != process.env.REACT_APP_AGENT_ROLE_ID ||
			door.commission_flag == 0
		) {
			for (const key in door.data) {
				if (door.data.hasOwnProperty(key)) {
					// address sector data status
					// description height width length top_gap bottom_gap left_gap right_gap direction createdById
					if (key == "description" || key == "direction") {
						// .noOfSensors
						const value = door.data[key] + "";

						// Check if the field value contains the search query (case-insensitive)
						if (
							value &&
							value
								.toString()
								.toLowerCase()
								.match(searchQuery.toLowerCase())
						) {
							each = true; // Return true if a match is found
						}
					} else if (!searchQuery) {
						const value = door.data[key] + "";

						// Check if the field value contains the search query (case-insensitive)
						if (
							value &&
							value
								.toString()
								.toLowerCase()
								.match(searchQuery.toLowerCase())
						) {
							each = true; // Return true if a match is found
						}
					}
				}
			}
			return each;
		}
	});
	useEffect(() => {
		document.title = "Doors";
	}, []);

	// const handleDeleteRole = (id) => {
	// 	dispatch(deleteDoor(id)).then(() => {
	// 		dispatch(getDoors());
	// 	});
	// };

	// State to manage the open state of the confirmation modal
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedDoorId, setSelectedDoorId] = useState(null);

	const handleDeleteRole = (id) => {
		// Open the confirmation modal before deleting
		setSelectedDoorId(id);
		setOpenDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		// Delete the door after user confirmation
		if (selectedDoorId) {
			dispatch(deleteDoor(selectedDoorId)).then(() => {
				dispatch(getDoors());
			});
			// Close the modal
			setOpenDeleteModal(false);
		}
	};

	const columns = [
		// {
		//   field: "floor", headerName: "Floor", flex: 1, renderCell: (params) => (
		//     <p>{params.row.floor.description}</p>
		//   )
		// },
		{
			field: "data",
			headerName: "Harmony Code",
			flex: 1,
			sortComparator: (v1, v2) => v1.description.localeCompare(v2),
			renderCell: (params) => {
				return (
					<Headings className="text-base font-futura-book font-extralight capitalize">
						{params.row.data?.description}
					</Headings>
				);
			},
		},

		{
			field: "organization",
			headerName: "Organization",
			flex: 1,

			renderCell: (params) => {
				return (
					<Headings className="text-base font-futura-book font-extralight capitalize">
						{params.row?.organization}
					</Headings>
				);
			},
		},
		{
			field: "site",
			headerName: "Site",
			flex: 1,

			renderCell: (params) => {
				return (
					<Headings className="text-base font-futura-book font-extralight capitalize">
						{params.row?.site}
					</Headings>
				);
			},
		},
		{
			field: "floor",
			headerName: "Floor",
			flex: 1,

			renderCell: (params) => {
				return (
					<Headings className="text-base font-futura-book font-extralight capitalize">
						{params.row?.floor}
					</Headings>
				);
			},
		},

		{
			field: "door_type",
			headerName: "Door Type",
			flex: 1,

			renderCell: (params) => {
				return (
					<Headings className="text-base font-futura-book font-extralight capitalize">
						{params.row.data?.door_type}
					</Headings>
				);
			},
		},

		{
			field: "status",
			headerName: "Door Status",
			flex: 1.5,
			renderCell: (params) => {
				const { color, background } = getStatus(params.row.status);

				return (
					<div
						className="flex justify-center items-center h-8 py-2 w-full"
						style={{
							backgroundColor: background,
						}}>
						<Headings
							className="text-base font-futura-book font-normal"
							color={color}>
							{getStatusMessage(params.row.status)}
						</Headings>
					</div>
				);
			},
		},

		{
			field: "actions",
			headerName: "Actions",
			width: 150,
			sortable: false,
			renderCell: (params) => {
				return (
					<ActionsDropdown
						roleId={params.row.data.id}
						onDelete={handleDeleteRole}
						setOpen={setOpen}
						setDoorId={setDoorId}
						setOpenIspection={setOpenIspection}
					/>
				);
			},
		},
	];

	useEffect(() => {
		dispatch(
			getDoors({
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				search: searchQuery,
				sort: sortModel[0].field,
				order: sortModel[0].sort,
			})
		);
	}, [dispatch, paginationModel, sortModel, searchQuery]);

	return (
		<div className="OverAllSites">
			<div className="searchspace">
				<div className="Heading">
					<h1 className="text-xl font-medium">Doors</h1>
				</div>
				<div className="inputdiv">
					<img src={search} alt="No search" className="searchimage" />
					<input
						className="searchinput"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>
			<div className="w-full !min-h-96">
				<DataGrid
					rows={doors}
					columns={columns}
					rowCount={count || 0}
					sortModel={sortModel}
					onSortModelChange={(model) =>
						model.length > 0 ? setSortModel(model) : null
					}
					getRowId={(row) => row?.data?.id}
					rowSelection={false}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					onRowClick={handleRowClick}
					matches={matches}
					columnVisibilityModel={{
						actions:
							user.roles.id ==
							process.env.REACT_APP_ADMIN_ROLE_ID,
					}}
					className="py-[10px] px-[30px] w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
					sx={{
						"& .MuiDataGrid-iconButtonContainer": {
							visibility: "visible",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
							padding: "0",
							color: "#212B36",
							fontFamily: "Futura PT Book !important",
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							fontFamily: "Futura PT",
							fontWeight: "500",
							fontSize: 17,
							padding: "0",
						},
						"& .MuiTablePagination-select": {
							background: "#EEE",
						},
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& > .MuiDataGrid-columnSeparator": {
							display: "none !important",
						},
					}}
					disableColumnMenu
					disableColumnSelector
				/>
			</div>
			<CustomModal
				isOpen={open}
				setIsOpen={setOpen}
				id={doorId}
				error={error}
			/>
			<Inspection
				isOpen={openInspection}
				setIsOpen={setOpenIspection}
				id={doorId}
				error={error}
			/>

			<DeleteConfirmationModal
				open={openDeleteModal}
				onClose={() => setOpenDeleteModal(false)}
				onConfirm={handleConfirmDelete}
			/>
		</div>
	);
};

// Separate component for delete confirmation modal
const DeleteConfirmationModal = ({ open, onClose, onConfirm }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="delete-confirmation-title"
			aria-describedby="delete-confirmation-description">
			<Box
				className="absolute top-1/2 left-1/2 w-[600px] shadow-sm p-6 -translate-x-1/2 -translate-y-1/2 rounded-md"
				sx={{
					bgcolor: "background.paper",
				}}>
				<h2 className="text-lg font-medium">Confirm Deletion</h2>
				<p className="mb-4">
					Are you sure you want to delete this door? This action
					cannot be undone.
				</p>
				<Box className="flex gap-2 mt-6">
					<Button
						variant="contained"
						color="error"
						onClick={onConfirm}>
						Confirm Delete
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						onClick={onClose}
						className="ml-1">
						Cancel
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default Doors;

const ActionsDropdown = ({
	roleId,
	onDelete,
	setOpen,
	setDoorId,
	setOpenIspection,
}) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const openModal = (event) => {
		setDoorId(roleId);
		setOpen(true);
	};

	const openInspection = (event) => {
		setDoorId(roleId);
		setOpenIspection(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDelete(roleId);
		handleClose();
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem onClick={() => navigate(`/doors/${roleId}`)}>
					<EditIcon fontSize="small" />
					Edit
				</MenuItem>
				<MenuItem onClick={openModal}>
					<VisibilityIcon fontSize="small" />
					View Assets
				</MenuItem>
				<MenuItem onClick={openInspection}>
					<VisibilityIcon fontSize="small" />
					Add Inspection
				</MenuItem>
			</Menu>
		</div>
	);
};

const getStatus = (stat) => {
	console.log(stat)
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "rgba(235, 67, 53, 1)",
			background: "rgba(235, 67, 53, 0.1)",
		};
	} else {
		return {
			color: "#FFA500",
			background: "rgba(254, 216, 177, 0.1)",
		};
	}
};

const getDirection = (direction) => {
	switch (direction) {
		case "1":
			return "Clock Wise";
		case "2":
			return "Counter Clock Wise";
		default:
			return "Clock Wise";
	}
};
