import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Modal, Box, Typography, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { getUsers } from "../../Store/Features/Users";
import { useDispatch, useSelector } from "react-redux";

import { usePlacesWidget } from "react-google-autocomplete";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

import {
	setKey,
	setDefaults,
	setLanguage,
	setRegion,
	fromAddress,
	fromLatLng,
	fromPlaceId,
	setLocationType,
	geocode,
	RequestType,
} from "react-geocode";

const BuildingForm = ({
	handleNext,
	formData,
	setFormData,
	loading,
	siteId,
	organization,
	buildingType,
}) => {
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();
	const { value } = useSelector((state) => state.users);
	const { user } = useSelector((state) => state.auth);
	const [autoComplete, setAutoComplete] = useState(null);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	setDefaults({
		key: process.env.REACT_APP_GOOGLE_API, // Your API key here.
		language: "en", // Default language for responses.
		region: "uk", // Default region for responses.
	});
	const handleLoad = (auto) => {
		setAutoComplete(auto);
	};
	useEffect(() => {
		dispatch(getUsers());
	}, []);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	const validateForm = () => {
		const newErrors = {};
		let valid = true;

		if (!formData.name?.trim()) {
			newErrors.name = "Name is required";
			valid = false;
		}

		if (!formData.address?.trim()) {
			newErrors.address = "Address is required";
			valid = false;
		}

		if (!formData.sector?.trim()) {
			newErrors.sector = "Building Type is required";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			handleNext();
		}
	};
	const defaultCenter = {
		lat: 54.251186, // Default latitude
		lng: -4.463196, // Default longitude
	};
	const center = {
		lat: 54.251186,
		lng: -4.463196,
	};

	const [selectedLocation, setSelectedLocation] = useState({
		lat: "",
		lng: "",
	});
	const [centerMap, setCenterMap] = useState(center);

	const handlePlaceSelect = () => {
		if (autoComplete !== null) {
			const place = autoComplete.getPlace();

			if (place.geometry && place.geometry.location) {
				const lat = place.geometry.location.lat();
				const lng = place.geometry.location.lng();

				setFormData({
					...formData,
					address: place.formatted_address,
					latitude: lat,
					longitude: lng,
				});
			} else {
				console.error(
					"No geometry information available for the selected place"
				);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextField
						label="Name"
						variant="filled"
						fullWidth
						name="name"
						value={formData.name}
						onChange={handleChange}
						error={!!errors.name}
						helperText={errors.name}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<Autocomplete
						onPlaceChanged={handlePlaceSelect}
						onLoad={handleLoad}>
						<TextField
							value={formData.address || ""}
							onChange={(e) =>
								setFormData((prev) => ({
									...prev,
									address: e.target.value,
								}))
							}
							fullWidth
							onKeyDown={handleKeyDown}
							label="Address"
							variant="filled"
							// inputRef={materialRef}
						/>
					</Autocomplete>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextField
						select
						label="Building Type"
						variant="filled"
						fullWidth
						name="sector"
						value={formData.sector || ""}
						onChange={handleChange}
						error={!!errors.sector}
						helperText={errors.sector}>
						{buildingType?.map((type) => (
							<MenuItem key={type} value={type}>
								{type}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextField
						select
						label="Organisation"
						variant="filled"
						fullWidth
						sx={{
							display:
								user.roleId ==
								process.env.REACT_APP_ADMIN_ROLE_ID
									? null
									: "none",
						}}
						name="organization"
						value={formData.organization || ""}
						onChange={handleChange}
						// error={!!errors.assignee}
						// helperText={errors.buildingType}
					>
						{organization?.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.name}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={12}>
					<Button
						className="customButtomOverAll"
						variant="contained"
						color="primary"
						type="submit"
						disabled={loading}>
						{loading ? (
							<CircularProgress
								color="inherit"
								sx={{ color: "white" }}
							/>
						) : siteId ? (
							"Update"
						) : (
							"Create"
						)}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default BuildingForm;
