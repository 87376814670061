import React from 'react';
import { Tooltip } from "@mui/material";
import GapWarningIcon from "../../Screens/Asset/icons/GapWarning";
import SlamWarningIcon from "../../Screens/Asset/icons/SlamWarning";
import OrientationChangeIcon from "../../Screens/Asset/icons/OrientationChange";
import OpenTimeoutIcon from "../../Screens/Asset/icons/OpenTimeout";
import VandalishWarningIcon from "../../Screens/Asset/icons/VandalishWarning";
import LowBatteryIcon from "../../Screens/Asset/icons/LowBattery";
import SystemResetStatusIcon from "../../Screens/Asset/icons/SystemResetStatus";
import LowSignalIcon from "../../Screens/Asset/icons/LowSignal";
import StatusMissedIcon from "../../Screens/Asset/icons/StatusMissed";


const Colors = {
  GREEN: "#34A853",
  YELLOW: "#DDAD01",
  RED: "#EB4335"
};


const StatusCode = {
  COMPLIANT: "200",
  GAP_WARNING: "301",
  SLAM_WARNING: "302",
  VANDALISM_WARNING: "303",
  LOW_BATTERY_WARNING: "304",
  SYSTEM_RESET_WARNING: "305",
  LOW_SIGNAL_WARNING: "306",
  GAP_WARNING_ALT: "308",
  GAP_ALERT: "401",
  ORIENTATION_CHANGE_ALERT: "402",
  OPEN_TIMEOUT_ALERT: "403",
  CRITICAL_BATTERY_ALERT: "404",
  CRITICAL_BATTERY_ALERT_ALT: "406",
  OPEN_TIMEOUT_ALERT_ALT: "407",
  STATUS_MISSED_ALERT: "409",
  ORIENTATION_CHANGE_ALERT_ALT: "411",
  GAP_ALERT_ALT: "412",
};

const checkStatusCode = (allStatus, statusCode) => {
  return allStatus?.split(',').includes(statusCode);
};

const getSystemResetWarning = (sensor) => {
  return checkStatusCode(sensor.allStatus, StatusCode.SYSTEM_RESET_WARNING);
};

const getLowSignalWarning = (sensor) => {
  return checkStatusCode(sensor.allStatus, StatusCode.LOW_SIGNAL_WARNING);
};

const StatusIndicator = ({ Icon, color, tooltip }) => (
  <Tooltip title={tooltip} placement="top-start">
    <div className="mb-2">
      <Icon color={color} />
    </div>
  </Tooltip>
);

const getBatteryStatus = (data) => {
  if (!data || data.length === 0) return 'NORMAL';

  const batteryStates = data
    .filter(item => item?.Data?.uplink_message?.decoded_payload?.msgType === 0)
    .slice(0, 3)
    .map(item => {
      const bat = item.bat;
      if (bat <= 3454) return 'CRITICAL';
      if (bat <= 3470) return 'LOW';
      return 'NORMAL';
    });

  if (batteryStates.length === 0) return 'NORMAL';
  if (batteryStates.every(state => state === 'CRITICAL')) return 'CRITICAL';
  if (batteryStates.every(state => state === 'CRITICAL' || state === 'LOW')) return 'LOW';
  return 'NORMAL';
};

const getOpenTimeoutStatus = (sensors) => {
  return sensors.some(sensor => {
    const latestData = sensor.latestData;
    console.log('latestdata', latestData);
    if (!latestData) return false;
    const msgType = latestData?.Data?.uplink_message?.decoded_payload?.msgType;
    const doorStatus = latestData?.Data?.uplink_message?.decoded_payload?.doorStatus;
    return doorStatus !== 1 || msgType === 1;
  });
};

const calculateDoorCounts = (data) => {
  if (data.length < 2) return { openCount: 0, slamCount: 0 };
  
  const latest = data[0].Data.uplink_message.decoded_payload;
  const oldest = data[data.length - 1].Data.uplink_message.decoded_payload;
  
  let openCount = latest.doorOpenCount - oldest.doorOpenCount;
  let slamCount = latest.doorSlamCount - oldest.doorSlamCount;
  
  if (openCount < 0) openCount += 65536;
  if (slamCount < 0) slamCount += 65536;
  
  return { openCount, slamCount };
};

const getSlamWarningStatus = (sensors) => {
  return sensors.some(sensor => {
    const { openCount, slamCount } = calculateDoorCounts(sensor.last24HoursData);
    return slamCount >= openCount * 0.75;
  });
};

const getVandalismWarningStatus = (sensors) => {
  return sensors.some(sensor => {
    const { openCount, slamCount } = calculateDoorCounts(sensor.last24HoursData);
    return slamCount >= openCount + 5;
  });
};

const getOrientationChangeStatus = (sensors) => {
  return sensors.some(sensor => {
    const latestData = sensor.latestData;
    if (!latestData) return false;
    const msgType = latestData?.Data?.uplink_message?.decoded_payload?.msgType;
    return msgType === 5;
  });
};

const getStatusMissedAlert = (sensor) => {
  return checkStatusCode(sensor.allStatus, StatusCode.STATUS_MISSED_ALERT);
};

const getGapStatus = (sensors) => {
  const warningCodes = [StatusCode.GAP_WARNING, StatusCode.GAP_WARNING_ALT];
  const alertCodes = [StatusCode.GAP_ALERT, StatusCode.GAP_ALERT_ALT];
  
  const hasWarning = sensors.some(sensor => checkStatusCode(sensor.allStatus, warningCodes));
  const hasAlert = sensors.some(sensor => checkStatusCode(sensor.allStatus, alertCodes));

  if (hasAlert) {
    return { status: 'ALERT', color: Colors.RED };
  } else if (hasWarning) {
    return { status: 'WARNING', color: Colors.YELLOW };
  } else {
    return { status: 'NORMAL', color: Colors.GREEN };
  }
};

export const SensorStatusIndicators = ({ sensor }) => {
  const { last3Data } = sensor;

  const batteryStatus = getBatteryStatus(last3Data);
  const statusMissedAlert = getStatusMissedAlert(sensor);

  const systemResetWarning = getSystemResetWarning(sensor);
  const lowSignalWarning = getLowSignalWarning(sensor);

  const getBatteryColor = (status) => {
    switch (status) {
      case 'CRITICAL': return Colors.RED;
      case 'LOW': return Colors.YELLOW;
      default: return Colors.GREEN;
    }
  };

  const getBatteryTooltip = (status) => {
    switch (status) {
      case 'CRITICAL': return 'Sensor – Critically Low Battery Status – Please Arrange for Replacement';
      case 'LOW': return 'Sensor - Low Battery Status – Limited life remaining';
      default: return 'Battery Status Normal';
    }
  };

  const batteryColor = getBatteryColor(batteryStatus);
  const batteryTooltip = getBatteryTooltip(batteryStatus);

  return (
    <ul className="flex items-center gap-6">
      <li>
        <StatusIndicator
          Icon={LowBatteryIcon}
          color={batteryColor}
          tooltip={batteryTooltip}
        />
      </li>
      <li>
        <StatusIndicator
          Icon={StatusMissedIcon}
          color={statusMissedAlert ? Colors.RED : Colors.GREEN}
          tooltip={statusMissedAlert ? 'Fire Door – More Than 2 Scheduled transmissions have been missed, Fire Door May be Non-Compliant' : 'Status Transmissions Normal'}
        />
      </li>
      <li>
        <StatusIndicator
          Icon={SystemResetStatusIcon}
          color={systemResetWarning ? Colors.YELLOW : Colors.GREEN}
          tooltip={systemResetWarning ? 'System Warning – Please Contact Customer Support' : 'System Status Normal'}
        />
      </li>
      <li>
        <StatusIndicator
          Icon={LowSignalIcon}
          color={lowSignalWarning ? Colors.YELLOW : Colors.GREEN}
          tooltip={lowSignalWarning ? 'System Warning – Please Contact Customer Support' : 'Signal Strength Normal'}
        />
      </li>
    </ul>
  );
};

export const DoorStatusIndicators = ({ sensors }) => {
  if (!sensors || !Array.isArray(sensors) || sensors.length === 0) {
    return null;
  }

  const aggregateBatteryStatus = (sensors) => {
    const batteryStatuses = sensors.map(sensor => getBatteryStatus(sensor.last3Data));
    if (batteryStatuses.some(status => status === 'CRITICAL')) return 'CRITICAL';
    if (batteryStatuses.some(status => status === 'LOW')) return 'LOW';
    return 'NORMAL';
  };

  const doorBatteryStatus = aggregateBatteryStatus(sensors);
  const doorOpenTimeoutStatus = getOpenTimeoutStatus(sensors);
  const doorOrientationChangeStatus = getOrientationChangeStatus(sensors);

  const doorSlamWarningStatus = getSlamWarningStatus(sensors);
  const doorVandalismWarningStatus = getVandalismWarningStatus(sensors);
  const doorGapStatus = getGapStatus(sensors);

  const getBatteryColor = (status) => {
    switch (status) {
      case 'CRITICAL': return Colors.RED;
      case 'LOW': return Colors.YELLOW;
      default: return Colors.GREEN;
    }
  };

  const getBatteryTooltip = (status) => {
    switch (status) {
      case 'CRITICAL': return 'Door – Critically Low Battery Status – Please Arrange for Replacement';
      case 'LOW': return 'Door - Low Battery Status – Limited life remaining';
      default: return 'Door Battery Status Normal';
    }
  };

  return (
    <div className="flex gap-4">
      <StatusIndicator
        Icon={LowBatteryIcon}
        color={getBatteryColor(doorBatteryStatus)}
        tooltip={getBatteryTooltip(doorBatteryStatus)}
      />
      <StatusIndicator
        Icon={OpenTimeoutIcon}
        color={doorOpenTimeoutStatus ? Colors.RED : Colors.GREEN}
        tooltip={doorOpenTimeoutStatus ? 'Fire Door – Has been Continuously Open Longer than (set time) Minutes' : 'Door Closing Time Normal'}
      />
       <StatusIndicator
        Icon={OrientationChangeIcon}
        color={doorOrientationChangeStatus ? Colors.RED : Colors.GREEN}
        tooltip={doorOrientationChangeStatus ? 'Sensor – Measurements Indicate Sensor or Door Removal or Severe Damage' : 'Orientation Normal'}
      />
      <StatusIndicator
        Icon={SlamWarningIcon}
        color={doorSlamWarningStatus ? Colors.YELLOW : Colors.GREEN}
        tooltip={doorSlamWarningStatus ? 'Fire Door - Indicated Frequent Slamming' : 'Slam Status Normal'}
      />
      <StatusIndicator
        Icon={VandalishWarningIcon}
        color={doorVandalismWarningStatus ? Colors.YELLOW : Colors.GREEN}
        tooltip={doorVandalismWarningStatus ? 'Fire Door - Measurements Indication of Vandalism' : 'No Vandalism Detected'}
      />
       <StatusIndicator
        Icon={GapWarningIcon}
        color={doorGapStatus.color}
        tooltip={
          doorGapStatus.status === 'ALERT' ? 'Fire Door - Gaps Indicated Non-compliant' :
          doorGapStatus.status === 'WARNING' ? 'Fire Door – Gap Close to Tolerance Limits' :
          'Fire Door - Indicated Compliant'
        }
      />
    </div>
  );
};

const isStatusCodePresent = (allStatus, statusCodes) => {
  const statusArray = allStatus?.split(',');
  return statusCodes.some(code => statusArray?.includes(code));
};

export const mainStatusIndicator = ({ sensors }) => {
  if (!sensors || !Array.isArray(sensors) || sensors.length === 0) {
    return { status: 'UNKNOWN', color: Colors.GREEN, background: "#E1FFE9" };
  }

  const combinedAllStatus = sensors.reduce((acc, sensor) => {
    if (sensor && sensor.allStatus) {
      return acc + ',' + sensor.allStatus;
    }
    return acc;
  }, '');

  const alertCodes = [
    StatusCode.GAP_ALERT, StatusCode.GAP_ALERT_ALT,
    StatusCode.ORIENTATION_CHANGE_ALERT, StatusCode.ORIENTATION_CHANGE_ALERT_ALT,
    StatusCode.OPEN_TIMEOUT_ALERT, StatusCode.OPEN_TIMEOUT_ALERT_ALT,
    StatusCode.CRITICAL_BATTERY_ALERT, StatusCode.CRITICAL_BATTERY_ALERT_ALT,
    StatusCode.STATUS_MISSED_ALERT
  ];

  const warningCodes = [
    StatusCode.GAP_WARNING, StatusCode.GAP_WARNING_ALT,
    StatusCode.SLAM_WARNING, StatusCode.VANDALISM_WARNING,
    StatusCode.LOW_BATTERY_WARNING, StatusCode.SYSTEM_RESET_WARNING,
    StatusCode.LOW_SIGNAL_WARNING
  ];

  if (isStatusCodePresent(combinedAllStatus, alertCodes)) {
    return { status: 'CRITICAL', color: Colors.RED, background: "#FFE6E6" };
  } else if (isStatusCodePresent(combinedAllStatus, warningCodes)) {
    return { status: 'WARNING', color: Colors.YELLOW, background: "#FEFFE1" };
  } else {
    return { status: 'COMPLIANT', color: Colors.GREEN, background: "#E1FFE9" };
  }
};