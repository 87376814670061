import React from "react";

const GapWarningIcon = ({ color }) => (
	<svg
		width="27"
		height="27"
		viewBox="0 0 27 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="10.1688"
			y="2.63388"
			width="15.1042"
			height="21.3725"
			fill="white"
			stroke={color}
			strokeWidth="1.82"
		/>
		<path
			d="M6.40805 24.0942V2.54579L21.5123 1.00739V25.6326L6.40805 24.0942Z"
			fill="white"
			stroke={color}
			strokeWidth="1.82"
		/>
		<circle
			cx="18.035"
			cy="12.6932"
			r="1.09694"
			fill={color}
			stroke={color}
			strokeWidth="0.313412"
		/>
	</svg>
);

export default GapWarningIcon;
