import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AddressForm from "../../Components/Forms/AddressForm";
import { useParams } from "react-router-dom";
import {
	getSites,
	getSite,
	addSite,
	updateSite,
	deleteSite,
} from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getOrganizations } from "../../Store/Features/Customer";

export default function AddSiteFormWizard() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { value } = useSelector((state) => state.customers);
	const { siteId } = useParams();
	const { loading } = useSelector((state) => state.sites);
	const [activeStep, setActiveStep] = useState(0);
	const [formData, setFormData] = useState({
		name: "",
		address: "",
		sector: "",
		solution: "",
		organization: user.organizationId || null,
		latitude: "",
		longitude: "",
		addedBy: user?.id,
	});

	const buildingTypes = [
		"House",
		"Flat",
		"Hospital",
		"Multi Story Carpark",
		"Hotel",
		"Office Block",
		"Care Home",
	];

	useEffect(() => {
		if (siteId) {
			dispatch(getSite(siteId)).then((res) =>
				setFormData({
					name: res.payload.name,
					address: res.payload.address,
					organization: res.payload.organization?.id,
					latitude: res.payload.latitude,
					longitude: res.payload.longitude,
					sector: res.payload.sector,
					solution: res.payload.solution,
					addedBy: res.payload.addedById,
					addedBy: user?.id,
				})
			);
		}
	}, [dispatch, siteId, user]);

	useEffect(() => {
		document.title = siteId ? "Edit Sites" : "Add Sites";
	}, []);

	const handleNext = () => {
		if (siteId) {
			dispatch(updateSite({ id: siteId, data: formData })).then(() => {
				navigate("/sites");
			});
		} else {
			dispatch(addSite({ data: formData })).then(() => {
				navigate("/sites");
			});
		}
	};

	useEffect(() => {
		dispatch(getOrganizations());
	}, [dispatch]);

	return (
		<Box className="flex flex-col gap-[30px] w-full p-[30px] box-border">
			<div className="flex items-center gap-[15px]">
				<IconButton onClick={() => navigate(-1)}>
					<KeyboardBackspaceIcon />
				</IconButton>
				<h1 className="text-xl font-medium">
					{siteId ? "Edit Site" : "Add Site"}
				</h1>
			</div>
			<div className="bg-white shadow-sm box-border p-6">
				<AddressForm
					formData={formData}
					setFormData={setFormData}
					handleNext={handleNext}
					siteId={siteId}
					loading={loading}
					organization={value}
					buildingType={buildingTypes}
				/>
			</div>
		</Box>
	);
}
