import React from "react";
import { Headings } from "../Fonts/Fonts";
import "./Custom.css";

export const Button = ({ title, href, color, hide }) => {
	return (
		<button className="customButton" disabled={hide}>
			<a
				className={
					hide
						? "achor-disable text-sm leading-6 font-medium capitalize font-futura no-underline"
						: null
				}
				style={{
					color: hide ? "grey" : color,
				}}
				href={href}
				target="_blank"
				rel="noreferrer"
				download>
				{title}
			</a>
		</button>
	);
};
