import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import Avatar from "@mui/material/Avatar";
import { profile } from "../../Store/Features/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
	getUser,
	updateUser,
	addUser,
	uploadAvatar,
} from "../../Store/Features/Users";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { API_URL } from "../../Utils/Functions/utils";

const Profile = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { loading } = useSelector((state) => state.users);

	const { slug } = useParams();
	const isEdit = slug !== undefined;
	const [showPassword, setShowPassword] = React.useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		address: "",
		avatar: "",
		roles: "",
		password: "",
	});
	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const validateForm = () => {
		const newErrors = {};
		let valid = true;

		if (!formData.name.trim()) {
			newErrors.name = "Name is required";
			valid = false;
		}

		if (!formData.address.trim()) {
			newErrors.address = "Address is required";
			valid = false;
		}

		if (!formData.email.trim()) {
			newErrors.email = "Email is required";
			valid = false;
		} else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
			newErrors.email = "Invalid email format";
			valid = false;
		}

		if (!formData.phoneNumber.trim()) {
			newErrors.phoneNumber = "Phone number is required";
			valid = false;
		} else if (!/^\d{10}$/.test(formData.phoneNumber)) {
			newErrors.phoneNumber = "Invalid phone number (10 digits required)";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleFormSubmit = (event) => {
		event.preventDefault();

		if (formData.password != "") {
			dispatch(updateUser({ id: user.id, data: formData })).then(
				(res) => {
					dispatch(profile());
				}
			);
		} else {
			const { password, ...rest } = formData;
			dispatch(updateUser({ id: user.id, data: rest })).then((res) => {
				dispatch(profile());
			});
		}
	};
	const handleAvatar = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	useEffect(() => {
		if (user) {
			setFormData({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				avatar: user.avatar,
				roles: user.roleId,
			});
		}
	}, [user]);

	useEffect(() => {
		document.title = "Profile";
	}, []);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<div className="OverAllSites">
			<Headings className="text-2xl">Profile</Headings>
			<div className="Addsitetabs">
				<form
					className="flex flex-col gap-[10px] items-center"
					onSubmit={handleFormSubmit}>
					<Avatar
						className="w-[72px] h-[72px]"
						src={
							formData.avatar[0]?.publicUrl ||
							formData.avatar?.publicUrl
						}
					/>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextField
								label="First Name"
								variant="filled"
								fullWidth
								name="firstName"
								value={formData.firstName}
								onChange={handleChange}
								error={!!errors.firstName}
								helperText={errors.firstName}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextField
								label="Last Name"
								variant="filled"
								fullWidth
								name="lastName"
								value={formData.lastName}
								onChange={handleChange}
								error={!!errors.lastName}
								helperText={errors.lastName}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Email"
								variant="filled"
								fullWidth
								name="email"
								value={formData.email}
								onChange={handleChange}
								error={!!errors.email}
								helperText={errors.email}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextField
								label="Phone Number"
								variant="filled"
								fullWidth
								name="phoneNumber"
								value={formData.phoneNumber}
								onChange={handleChange}
								error={!!errors.phoneNumber}
								helperText={errors.phoneNumber}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<TextField
								label="Password"
								variant="filled"
								placeholder="Enter your password"
								type={showPassword ? "text" : "password"}
								fullWidth
								className="input-field"
								name="password"
								value={formData.password}
								onChange={handleChange}
								InputProps={{
									endAdornment: (
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
											edge="end">
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<ImageUploadButton
								onChange={handleAvatar}
								name={"avatar"}
								path={"users/avatar"}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Button
								className="button customButtomOverAll text-white capitalize"
								disabled={loading}
								variant="contained"
								type="submit">
								{loading ? (
									<CircularProgress color="inherit" />
								) : (
									"Update"
								)}
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	);
};

export default Profile;
const ImageUploadButton = ({ onChange, name, path }) => {
	const dispatch = useDispatch();

	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path}/${filename}`;
		const uri = `/file/upload/${path}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadAvatar(formData)).then((res) => {
			onChange("avatar", {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};

	return (
		<div>
			<input
				className="hidden"
				accept="image/*"
				id="image-upload-input"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input">
				<Button
					type="button"
					className="button customButtomOverAll text-white capitalize"
					variant="contained"
					component="span">
					Upload Image
				</Button>
			</label>
		</div>
	);
};

function extractExtensionFrom(filename) {
	if (!filename) {
		return null;
	}

	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(filename)[1];
}
