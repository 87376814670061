import React, { useState, useRef } from "react";
import "./SidePanels.css";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import { Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFloor } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import CanvasLoader from "../../Components/Canvas/Canvas";
import NotesModal from "../../Components/Modal/Notes";
export const AssetSidePanel = ({
	siteName,
	assetName,
	floorNumber,
	SitesLocation,
	image,
	sensors,
}) => {
	const [open, setOpen] = useState(false);
	const sidePanelAssetRef = useRef();
	return (
		<React.Fragment>
			<div className="sidePanelAsset" ref={sidePanelAssetRef}>
				<div className="UpperSection">
					<div className="names">
						<Headings className="text-lg">
							{siteName || ""}
						</Headings>
					</div>
					<div className="flex flex-col justify-between w-full">
						<div className="flex gap-[10px] items-center">
							<SubHeadings className="text-base text-[#637381]">
								Floor {floorNumber || ""}
							</SubHeadings>
							<div className="flex items-center w-1 h-1 rounded-full bg-[#637381]" />
							<SubHeadings className="text-base text-[#637381]">
								{assetName || ""}
							</SubHeadings>
						</div>
						<SubHeadings className="text-base text-[#637381]">
							{SitesLocation || ""}
						</SubHeadings>
					</div>
				</div>
				<Divider className="w-full" />

				<div className="flex items-center flex-col w-full gap-[30px]">
					<CanvasLoader parentRef={sidePanelAssetRef} />

					<img
						className="w-full h-[230px] border-none"
						style={{
							display: image ? null : "none",
						}}
						src={image}
						alt="Img"
					/>
				</div>
			</div>
			<NotesModal open={open} setOpen={setOpen} sensors={sensors} />
		</React.Fragment>
	);
};
