import React, { useEffect, useRef, useState } from "react";
import "./Asset.css";

import { useParams, useNavigate } from "react-router-dom";
import {
	DoorStatusIndicators,
	SensorStatusIndicators,
} from "./StatusIndicators";

import { IconButton } from "@mui/material";
import { Paper, CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

import { Link, Divider } from "@mui/material";
import Select from "../../Shared/CustomElement/DropDown";
import { Headings } from "../../Shared/Fonts/Fonts";
import JsonFormatter from "react-json-formatter";
import { Button } from "../../Shared/CustomElement/Button";
import { AssetSidePanel } from "../../Components/Drawers/AssetSidePanel";
import { getSensorsbyDoor } from "../../Store/Features/Sensors";
import { useDispatch } from "react-redux";
import { getDoor } from "../../Store/Features/Door";
import { getFloor } from "../../Store/Features/Floors";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getSites } from "../../Store/Features/Sites";
import { getDoorsofFloor } from "../../Store/Features/Door";
import { getWebhooks } from "../../Store/Features/Webhook";
import {
	getAllStatuses,
	getStatusMessages,
} from "../../Utils/Functions/getStatusMessages";
import SensorsIcon from "@mui/icons-material/Sensors";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import DynamicLineChart from "../../Components/Charts/AllTimeChart";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AdbIcon from "@mui/icons-material/Adb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { DoorCard } from "../../Components/Door/DoorCards";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DoorCardTimeOut } from "../../Components/Door/DoorCardTimeOut";
import { DoorCardUtil } from "../../Components/Door/DoorCardUitl";
import DynamicLineChartWeek from "../../Components/Charts/WeekChart";
import { formatDateToGB } from "../../Utils/Functions/utils";

export const Asset = () => {
	const navigate = useNavigate();
	const { siteId, floorId, assetId } = useParams();
	const matches = useMediaQuery("(max-width:769px)");
	const { value } = useSelector((state) => state.sites);
	const path = useParams().pathName;
	const dispatch = useDispatch();
	const [sensors, setSensors] = useState([]);
	const [sensorData, setSensorData] = useState([]);
	const [door, setDoor] = useState(assetId);
	const [floor, setFloor] = useState({});
	const [doors, setDoors] = useState([]);
	const { user } = useSelector((state) => state.auth);
	const [doorAllData, setDoorAllData] = useState([]);
	const [doorWeekData, setDoorWeekData] = useState([]);

	const [sites, setSites] = useState([]);
	const [floors, setFloors] = useState([]);

	const [site, setSite] = useState(0);

	const notifications = useSelector((state) => state.notifications.value);

	const now = new Date();
	const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
	const oneWeekAgoISO = oneWeekAgo.toISOString();

	const onChangeSite = (e) => {
		if (e != 0) {
			navigate(`/sites/${siteId}/floor/${floorId}/asset/${e}`);
			window.location.reload(false);
		}
	};

	useEffect(() => {
		dispatch(getSites());
	}, []);

	useEffect(() => {
		document.title = "Door";
	}, []);

	useEffect(() => {
		dispatch(getFloor(floorId)).then((res) => {
			setFloor(res.payload);
		});
	}, [floorId]);
	useEffect(() => {
		dispatch(getDoor(assetId)).then((res) => {
			setDoor(res.payload);
		});
	}, [assetId]);

	useEffect(() => {
		dispatch(getDoorsofFloor(floorId)).then((res) => {
			setDoors(res.payload.data);
		});
	}, []);
	useEffect(() => {
		dispatch(getSensorsbyDoor(assetId)).then((res) => {
			let temp = res.payload.rows;
			const sorted = temp?.sort(
				(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
			);
			setSensors(sorted);
			fetchSensorData(sorted);
		});
	}, [assetId]);

	const fetchSensorData = async (sensors) => {
        const sensorDataPromises = sensors.map(async (sensor) => {
            const latestRes = await dispatch(getWebhooks(`limit=1&Sensor=${sensor.sensor_code}&field=createdAt&sort=desc`));
            const last3Res = await dispatch(getWebhooks(`limit=3&Sensor=${sensor.sensor_code}&field=createdAt&sort=desc`));
            const twentyFourHoursAgo = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString();
            const last24HoursRes = await dispatch(getWebhooks(`Sensor=${sensor.sensor_code}&createdAtRange=${twentyFourHoursAgo}&createdAtRange=&sort=createdAt:desc`));

            return {
                ...sensor,
                latestData: latestRes.payload.rows[0],
                last3Data: last3Res.payload.rows,
                last24HoursData: last24HoursRes.payload.rows,
            };
        });

        const fullSensorData = await Promise.all(sensorDataPromises);
        setSensorData(fullSensorData);
    };

	useEffect(() => {
		if (sensors?.length > 0) {
			console.log("sensors", sensors);
			let query = "Sensor=";
			let modified = sensors
				.filter((ele) => ele.location === "Top")
				.map((ele) => ele.sensor_code)
				.join("|");

			dispatch(getWebhooks(`${query}${modified}`)).then((res) => {
				setDoorAllData(res.payload.rows);
			});

			dispatch(
				getWebhooks(
					`${query}${modified}&createdAtRange=${oneWeekAgoISO}&createdAtRange=&`
				)
			).then((res) => {
				setDoorWeekData(res.payload.rows);
			});
		}
	}, [sensors]);

	return (
		<div className="AssetPage">
			<div className="StatusPart">
				<div className="navBar">
					<div className="navBar w-100">
						<div className="TitleBack">
							<IconButton onClick={() => navigate(-1)}>
								<KeyboardBackspaceIcon />
							</IconButton>
							<Headings fontSize={18}>
								{
									value.find((ele) => ele.data.id == siteId)
										?.data.name
								}
							</Headings>
						</div>
						<div className="nav-end">
							<Select
								options={doors}
								onChange={onChangeSite}
								value={assetId}
								title={"Doors"}
								area={"doors"}
							/>
						</div>
					</div>
				</div>

				{matches ? (
					<div className="w-full">
						<Accordion
							className="background-bg-transparent border-b border-solid border-black"
							elevation={0}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								className="w-full">
								<Headings>Status Report</Headings>
							</AccordionSummary>
							<AccordionDetails>
								<div className="status-content">
								<DoorCard
  {...door}
  sensors={door.sensors}
  weekDoorWeekData={doorWeekData}
/>
									<DoorCardTimeOut {...door} />
								</div>
							</AccordionDetails>
						</Accordion>
						{sensors?.map((sensor) => {
							return (
								<Accordion
									key={sensor.id || sensor.sensor_code}
									className="bg-transparent border-b border-solid border-black"
									elevation={0}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2a-content"
										id="panel2a-header"
										className="w-full">
										<Headings>
											{`${sensor.location} Sensors`}{" "}
											{sensor.sensor_code}
										</Headings>
									</AccordionSummary>
									<AccordionDetails>
										<TopSensor  key={sensor.id || sensor.sensor_code} {...sensor} />
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>
				) : (
					<div className="status !pt-0">
						<h1 className="text-xl font-medium">Status Report</h1>
						<div className="grid grid-cols-[2fr_1fr] gap-[30px]">
							<div className="status-content">
								<DoorCard {...door} />
								{/* <DoorCardTimeOut {...door} /> */}
								<Paper className="px-[25px] py-8 border border-solid border-[#eeeeee] gap-[15px] flex-col flex !rounded-none !shadow-sm">
								<DoorStatusIndicators sensors={sensorData} />
								</Paper>
							</div>
							<div className="status-content">
								<Paper className="flex flex-col h-[300px] p-4 pb-10 !rounded-none !shadow-sm">
									<div className="flex justify-between">
										<Headings>Door Utilisation</Headings>
										<Headings color="#407AAD">
											Weekly
										</Headings>
									</div>

									<DynamicLineChartWeek
										data={doorWeekData}
										chartTitle="Dynamic Line Chart"
										xLabel="Date"
										yLabel="Value"
									/>
								</Paper>
								<Paper className="flex flex-col h-[300px] p-4 pb-10 !rounded-none !shadow-sm">
									<div className="flex justify-between">
										<Headings>Door Utilisation</Headings>
										<Headings color="#407AAD">
											Lifetime
										</Headings>
									</div>

									<DynamicLineChart
										data={doorAllData}
										chartTitle="Dynamic Line Chart"
										xLabel="Date"
										yLabel="Value"
									/>
								</Paper>
							</div>
							<div className="status-content">
								{sensorData?.map((sensor) => {
									return (
										<TopSensor key={sensor.id || sensor.sensor_code} {...sensor} />

									);
								})}
							</div>
							<div className="status-data">
								<Paper className="flex flex-col h-full p-4 pb-10 !rounded-none !shadow-sm">
									<div className="flex justify-between w-full items-center">
										<h2 className="text-lg font-medium">
											Event History
										</h2>
										<button
											className="viewEvents customButtomOverAll"
											onClick={() =>
												navigate("/webhooks")
											}>
											<Headings>View All Events</Headings>
										</button>
									</div>
									<div className="left-top-right-bottom-content mt-6">
										{notifications?.map((item, index) => {
											return (
												<div className="adminDashboard-installation">
													<div className="textArea-Right-Content">
														<Link className="cursor-pointer !no-underline">
															<Headings
																className="text-base font-medium font-futura"
																color={
																	getStatus(
																		item.status
																	).color
																}
																onClick={() => {
																	navigate(
																		`/sites/${item.siteId}/floor/${item.floorId}/asset/${item.doorId}`
																	);
																}}>
																{getStatusMessages(
																	item.status
																)}
															</Headings>
														</Link>
														<div className="textArea">
															<Headings className="text-base font-normal font-futura-book">
																{item.createdAt
																	.split(
																		"T"
																	)[1]
																	.split(
																		":"
																	)[0] +
																	":" +
																	item.createdAt
																		.split(
																			"T"
																		)[1]
																		.split(
																			":"
																		)[1]}
															</Headings>
															<Headings className="text-base font-normal font-futura-book">
																{
																	item.createdAt.split(
																		"T"
																	)[0]
																}
															</Headings>
														</div>
													</div>
													<Divider className="w-full" />
												</div>
											);
										})}
									</div>
								</Paper>
							</div>
						</div>
					</div>
				)}
			</div>
			<AssetSidePanel
				siteName={floor?.site?.name}
				assetName={door?.description}
				floorNumber={floor?.floor_number}
				SitesLocation={floor?.site?.address}
				image={
					door.door_image
						? door.door_image?.length > 0
							? door.door_image[0].publicUrl
							: null
						: null
				}
				sensors={sensors}
			/>
		</div>
	);
};

const TopSensor = ({ ...props }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const { siteId, floorId, assetId } = useParams();
    const {
        status,
        location,
        sensor_code,
        id,
        qr_code,
        allStatus,
		latestData
    } = props;
    const { user } = useSelector((state) => state.auth);
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const matches = useMediaQuery("(max-width:769px)");


    return (
        <>
            <div className="reportCards flex flex-col gap-[5px]">
                <Paper className="py-5 px-6 border border-solid border-[#eeeeee] gap-4 flex-col flex !rounded-none !shadow-sm">
                    {!matches && (
                        <div className="flex justify-between">
                            <h2 className="capitalize text-lg font-medium mb-2">
                                {location} Sensor
                            </h2>
                            <Headings className="capitalize text-base font-extrabold text-[#637381]">
                                {qr_code}
                            </Headings>
                        </div>
                    )}
                    <div className="flex justify-between flex-wrap">
                        {user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID && (
                            <div className="flex justify-between gap-[10px] w-full">
                                <div className="IconHold flex items-center justify-between gap-3 w-full">
                                    <SensorStatusIndicators sensor={props} />
                                    <div className="flex gap-3">
                                        <IconButton
                                            onClick={() => {
                                                setIsOpen(!isOpen);
                                                setCode(sensor_code);
                                            }}
                                        >
                                            <AdbIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                navigate(
                                                    `/sites/${siteId}/floor/${floorId}/asset/${assetId}/device/${sensor_code}`
                                                );
                                            }}
                                        >
                                            <SensorsIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="DoorReport-down">
                        <Headings className="text-base text-[#637381] font-medium">
                            Reports
                        </Headings>
                        <div className="DoorReport-Upper">
                            <Headings className="text-base text-[#637381] font-medium">
                                Last
                            </Headings>
                            <Headings
                                fontSize={16}
                                color="rgba(33, 43, 54, 1)"
                                fontWeigth={450}
                            >
                                {!loading && latestData?.createdAt
                                    ? `${latestData.createdAt.split("T")[1].split(":")[0] || ""}:${
                                          latestData.createdAt.split("T")[1].split(":")[1] || ""
                                      } - ${formatDateToGB(latestData.createdAt.split("T")[0]) || ""}`
                                    : "N/A"}
                            </Headings>
                        </div>
                        <div className="DoorReport-Upper">
                            <Headings
                                fontSize={16}
                                color="rgba(99, 115, 129, 1)"
                                fontWeigth={450}
                            >
                                Next
                            </Headings>
                            <Headings
                                fontSize={16}
                                color="rgba(33, 43, 54, 1)"
                                fontWeigth={450}
                            >
                                {!loading && latestData?.expectedTime
                                    ? `${latestData.expectedTime.split("T")[1].split(":")[0] || ""}:${
                                          latestData.expectedTime.split("T")[1].split(":")[1] || ""
                                      } - ${formatDateToGB(latestData.expectedTime.split("T")[0]) || ""}`
                                    : "N/A"}
                            </Headings>
                        </div>
                    </div>
                </Paper>
            </div>
            <BasicModal open={isOpen} setOpen={setIsOpen} id={code} />
        </>
    );
};

export default TopSensor;

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "#EB4335",
		};
	} else {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	}
};

function BasicModal({ open, setOpen, id }) {
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { value, loading } = useSelector((state) => state.webhooks);
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (id) {
			dispatch(
				getWebhooks(`limit=1&Sensor=${id}&field=createdAt&sort=desc`)
			).then((res) => {
				if (!res.error) {
					if (res.payload.rows?.length > 0) {
						setData(res.payload.rows[0]);
					}
				}
			});
		}
	}, [id]);

	const jsonStyle = {
		propertyStyle: { color: "red" },
		stringStyle: { color: "green" },
		numberStyle: { color: "darkorange" },
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box className="absolute shadow-sm top-1/2 left-1/2 h-[400px] w-[650px] overflow-scroll p-6 -translate-x-1/2 -translate-y-1/2 bg-white">
				{loading ? (
					<Box className="w-full h-full flex justify-center items-center">
						{" "}
						<CircularProgress />
					</Box>
				) : data != {} ? (
					<JsonFormatter
						json={data}
						tabWith={4}
						jsonStyle={jsonStyle}
					/>
				) : (
					"No Data available"
				)}
			</Box>
		</Modal>
	);
}
