import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { format, parseISO, subDays } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  TimeScale
);

const DynamicLineChartWeek = ({ data, chartTitle, xLabel, yLabel }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const today = new Date();
  const oneWeekAgo = subDays(today, 7);

  const processedData = data
    .filter(entry => entry && entry.createdAt && (entry.doorOpenCount !== undefined || entry.LOCAL_doorOpenCount !== undefined))
    .map(entry => ({
      date: parseISO(entry.createdAt),
      count: entry.doorOpenCount || entry.LOCAL_doorOpenCount || 0
    }))
    .filter(item => item.date >= oneWeekAgo && item.date <= today)
    .sort((a, b) => a.date - b.date);

  const chartData = {
    datasets: [
      {
        label: "Door Utilisation",
        data: processedData.map(item => ({ x: item.date, y: item.count })),
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        spanGaps: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: chartTitle,
      },
      tooltip: {
        callbacks: {
          title: (context) => format(context[0].parsed.x, 'yyyy-MM-dd HH:mm'),
          label: (context) => `Door Opens: ${context.parsed.y}`,
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM dd'
          },
          tooltipFormat: 'yyyy-MM-dd HH:mm'
        },
        title: {
          display: true,
          text: xLabel,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          font: {
            size: 10,
            family: "Futura Pt",
          },
        },
        min: oneWeekAgo,
        max: today,
      },
      y: {
        title: {
          display: true,
          text: yLabel,
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartWeek;