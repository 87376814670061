import React from "react";
import "./Door.css";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

export const DoorCardTimeOut = (props) => {
	const {
		status,
		last_inspection,
		due_inspection,
		week_Utilisation,
		lifetime_Utilisation,
		report_interval,
		open_interval,
		description,
		direction,
		doorType,
	} = props;

	console.log(
		status,
		last_inspection,
		due_inspection,
		week_Utilisation,
		lifetime_Utilisation,
		report_interval,
		description,
		doorType,
		open_interval
	);
	return (
		<div className="doorCard">
			<div className="doorCardUpper">
				<WatchLaterOutlinedIcon className="text-white" />
			</div>
			<div className="doorCardDown">
				<div className="doorCardDownUpTime">
					<h6 className="text-white text-2xl font-medium mt-1">
						Open Timeout
					</h6>
					<p className="text-white text-base font-normal">
						When a door has been open longer than this time, an
						alert will be triggered.
					</p>
				</div>
				<div className="flex justify-center items-center min-w-[74px] h-[25px] border border-solid border-white bg-transparent py-4 px-5 rounded-s">
					<h6 className="font-futura text-white text-base font-extralight capitalize">
						{open_interval} Minutes
					</h6>
				</div>
			</div>
		</div>
	);
};
