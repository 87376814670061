import React from "react";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import { Headings } from "../Fonts/Fonts";
import { useNavigate } from "react-router-dom";
// import ThreeGlobe from 'three-globe';
// import globe from "../../Assets/globe_3d.png"
import * as THREE from "three";
import Globe from "../CustomElement/Globe";

export const AuthLayout = () => {
	const navigate = useNavigate();

	// const myScene = new THREE.Scene();
	// myScene.add(myGlobe);
	return (
		<>
			<div className="background">
				<div className="login-home">
					<Outlet />
				</div>
				<div className="footer-pages">
					<div className="footer-links">
						<a
							className="no-underline"
							target="_blank"
							rel="noreferrer"
							href="https://harmonyfire.com/about-us/">
							<Headings color={"#637381"}>About</Headings>
						</a>
						<span style={{ color: "#637381" }}>|</span>
						<a
							target="_blank"
							rel="noreferrer"
							className="no-underline"
							style={{
								"&:focus": {
									color: "black",
								},
							}}
							href="https://harmonyfire.com/contact/">
							{" "}
							<Headings className="text-[#637381]">
								Contact
							</Headings>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};
