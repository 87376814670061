import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Roles.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";

import {
	Button,
	Menu,
	MenuItem,
	IconButton,
	Modal,
	TextField,
	Paper,
	Box,
	CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Edit from "../../Assets/Edit.svg";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import {
	getSensors,
	deleteSensor,
	getFloorOdSensor,
	getSensorOfOrg,
	scheduleDownlinkMessage,
} from "../../Store/Features/Sensors";
import { useDispatch, useSelector } from "react-redux";
import search from "../../Assets/search.svg";
import { getWebhooks } from "../../Store/Features/Webhook";

const Sensors = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		loading,
		value: sensors,
		count,
	} = useSelector((state) => state.sensors);
	const { user } = useSelector((state) => state.auth);
	const [searchQuery, setSearchQuery] = useState("");
	const matches = useMediaQuery("(max-width:769px)");
	const [isOpen, setIsOpen] = useState(false);
	const [sensor, setSensor] = useState();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleDeleteSensor = (id) => {
		dispatch(deleteSensor(id)).then(() => {
			fetchSensors();
		});
	};

	const fetchSensors = () => {
		const params = {
			page: paginationModel.page,
			limit: paginationModel.pageSize,
			sort: sortModel[0].sort,
			field: sortModel[0].field,
			search: searchQuery,
		};
		if (
			user?.roleId === process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
			user?.roleId === process.env.REACT_APP_USER_ROLE_ID
		) {
			if (user?.organizationId) {
				dispatch(
					getSensorOfOrg({ ...params, orgId: user.organizationId })
				);
			}
		} else {
			dispatch(getSensors(params));
		}
	};

	useEffect(() => {
		document.title = "Sensors";
	}, []);

	useEffect(() => {
		if (user != null && sortModel && sortModel[0].sort) {
			fetchSensors();
		}
	}, [dispatch, user, paginationModel, sortModel, searchQuery]);

	const columns = [
		{
			field: "door",
			headerName: "Door",
			flex: 1,
			renderCell: (params) => <p>{params.row.door?.description}</p>,
		},
		{
			field: "sensor_code",
			headerName: "Device Code",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
					{params.row.sensor_code.split("-")[1].toUpperCase()}
				</Headings>
			),
		},

		{
			field: "admin_sensor_code",
			headerName: "Admin Device Code",
			flex: 1,
			hideable: true,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
					{params.row.sensor_code}
				</Headings>
			),
		},
		{
			field: "siteName",
			headerName: "Site",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
					{params.row.siteName}
				</Headings>
			),
		},

		// params.row.door?.floorId
		{
			field: "floorName",
			headerName: "Floor",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
					{params.row.floorName}
				</Headings>
			),
		},
		{ field: "location", headerName: "location", flex: 0.5 },
		// { field: "status", headerName: "doorPi", flex: 1 },
		// { field: "createdById", headerName: "Commissioned By", flex: 1 },
		{
			field: "status",
			headerName: "Sensor Status",
			flex: 1,
			renderCell: (params) => {
				const { color, background } = getStatus(params.row.status);

				return (
					<div className="flex flex-col gap-[10px] p-[10px]">
						<div
							className="flex justify-center items-center h-[25px] py-[2px] px-5 box-border"
							style={{
								backgroundColor: background,
							}}>
							<Headings
								className="text-base text-[#212B36] font-futura font-extralight capitalize"
								color={color}>
								{getStatusMessage(params.row.status)}
							</Headings>
						</div>
						<img
							className="w-[50px] h-[50px] border border-solid border-transparent"
							style={{
								display: params.row.data?.door_image[0]
									?.publicUrl
									? null
									: "none",
							}}
							src={params.row.data?.door_image[0]?.publicUrl}
							alt="sensor"
						/>
					</div>
				);
			},
			sortComparator: (v1, v2) => v1.localeCompare(v2),
		},
		{
			field: "actions",
			headerName: "",
			width: 150,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					roleId={params.row.id}
					onDelete={handleDeleteSensor}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					device_id={params.row.sensor_code}
					setSensor={setSensor}
				/>
			),
		},
	];

	const mobileColumns = [
		{
			field: "data",
			headerName: "Device Information",
			flex: 1,
			sortable: true,
			renderCell: (params) => {
				return (
					<div className="flex flex-col gap-[10px]">
						<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
							{params.row.sensor_code}
						</Headings>
						<Headings className="text-base text-[#212B36] font-futura-book font-normal capitalize">
							{params.row.location}
						</Headings>
					</div>
				);
			},

			sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
		},
		{
			field: "site",
			headerName: "Site",
			flex: 1,
			renderCell: (params) => {
				return (
					<div className="flex flex-col gap-[10px] p-[10px]">
						<Headings className="text-base font-normal capitalize font-futura">
							{params.row.siteName}
						</Headings>
						<Headings className="text-base font-normal capitalize font-futura-book">
							{params.row.floorName}
						</Headings>
					</div>
				);
			},
			sortComparator: (v1, v2) => v1.localeCompare(v2),
		},
		{
			field: "status",
			headerName: "Device Status",
			flex: 1,
			renderCell: (params) => {
				const { color, background } = getStatus(params.row.status);

				return (
					<div className="flex flex-col gap-[10px] p-[10px]">
						<div
							className="flex justify-center items-center h-[25px] py-[2px] px-5 box-border"
							style={{
								backgroundColor: background,
							}}>
							<Headings
								className="font-futura text-base font-extralight capitalize"
								color={color}>
								{getStatusMessage(params.row.status)}
							</Headings>
						</div>
						<img
							className="w-[50px] h-[50px] border border-solid border-transparent"
							style={{
								display: params.row.data?.door_image[0]
									?.publicUrl
									? null
									: "none",
							}}
							src={params.row.data?.door_image[0]?.publicUrl}
							alt="Sensor"
						/>
					</div>
				);
			},
			sortComparator: (v1, v2) => v1.localeCompare(v2),
		},

		{
			field: "actions",
			headerName: "",
			sortable: false,

			flex: 0.2,
			renderCell: (params) => (
				<ActionsDropdown
					siteId={params.row.id}
					onDelete={handleDeleteSensor}
				/>
			),
		},
	];

	return (
		<div className="OverAllSites">
			<div className="searchspace">
				<div className="Heading">
					<h1 className="text-xl font-medium">Devices</h1>
					{(user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
						user?.roleId ==
							process.env.REACT_APP_ORG_ADMIN_ROLE_ID) && (
						<button
							className="addSiteButton customButtomOverAll"
							onClick={() => navigate("/devices/addDevice")}>
							<Headings>Add Device</Headings>
						</button>
					)}
				</div>
				<div className="inputdiv">
					<img src={search} alt="Search" className="searchimage" />
					<input
						className="searchinput"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>
			<div className="w-full !min-h-96">
				<DataGrid
					rows={sensors}
					columns={columns}
					rowCount={count}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					className="py-[10px] px-[30px] w-full bg-white !border-none !rounded-none shadow-sm !min-h-96"
					sx={{
						"& .MuiDataGrid-iconButtonContainer": {
							visibility: "visible",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
							padding: "0",
							color: "#212B36",
							fontFamily: "Futura PT Book !important",
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							fontFamily: "Futura PT",
							fontWeight: "500",
							fontSize: 17,
							padding: "0",
						},
						"& .MuiTablePagination-select": {
							background: "#EEE",
						},
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& > .MuiDataGrid-columnSeparator": {
							display: "none !important",
						},
					}}
					disableColumnMenu
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
					disableColumnSelector
					rowHeight={54}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					sortModel={sortModel}
					onSortModelChange={(model) =>
						model.length > 0 ? setSortModel(model) : null
					}
				/>
				<CustomModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					device_id={sensor}
				/>
			</div>
		</div>
	);
};

export default Sensors;

const ActionsDropdown = ({
	roleId,
	onDelete,
	isOpen,
	setIsOpen,
	device_id,
	setSensor,
}) => {
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDelete(roleId);
		handleClose();
	};
	const openModal = () => {
		setSensor(device_id);
		setIsOpen(true);
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem onClick={() => navigate(`/devices/${roleId}`)}>
					<EditIcon fontSize="small" />
					Edit
				</MenuItem>
				<MenuItem onClick={openModal}>
					{/* <VisibilityIcon fontSize="small" /> */}
					Downlink
				</MenuItem>
			</Menu>
		</div>
	);
};

const CustomModal = ({ device_id, isOpen, setIsOpen }) => {
	const dispatch = useDispatch();
	const { floorId } = useParams();
	const { loading } = useSelector((state) => state.webhooks);
	const loading1 = useSelector((state) => state.sensors.loading);
	const [application_id, setApplication_id] = useState();

	const [data, setData] = useState();
	// const handleEditFloor = () => {
	//   dispatch(updateFloor({ id: floorId, data: floorData })).then((res) => {
	//     if(!res.error) {
	//       handleClose()
	//     }
	//   });
	// };
	const [downlink, setDownLink] = useState({
		device_type: null,
		protocol_ver: null,
		param_id: null,
		param_value: null,
	});

	const params = [
		{ label: "Reserved", value: 0 },
		{ label: "Regular Reporting Interval", value: 1 },
		{ label: "Measurement Interval", value: 2 },
		{ label: "Door Open Timeout", value: 3 },
		{ label: "Acknowledge Reset Flag", value: 4 },
	];

	const handleClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (isOpen) {
			if (device_id) {
				dispatch(
					getWebhooks(
						`limit=1&Sensor=${device_id}&field=createdAt&sort=desc`
					)
				).then((res) => {
					if (!res.error) {
						if (res.payload.rows?.length > 0) {
							setData(res.payload.rows[0]);
						}
					}
				});
			}
		}
	}, [isOpen, device_id]);
	useEffect(() => {
		if (data) {
			setDownLink((prevData) => ({
				...prevData,
				["device_type"]:
					data?.Data.uplink_message.decoded_payload.deviceType,
				["protocol_ver"]:
					data?.Data.uplink_message.decoded_payload.protocolVer,
			}));
			setApplication_id(
				data?.Data.end_device_ids.application_ids.application_id
			);
		}
	}, [data]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		// Update the floorData state based on the field name
		// Assuming the keys in floorData match the field names
		setDownLink((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleChangeSelect = (e) => {
		setDownLink((prevData) => ({ ...prevData, ["param_id"]: e.value }));
	};
	const handleSchedule = () => {
		dispatch(
			scheduleDownlinkMessage({
				data: {
					application_id: application_id,
					payload: downlink,
					device_id: device_id,
				},
			})
		).then((res) => {
			if (!res.error) {
				setIsOpen(false);
			}
		});
	};

	return (
		<Modal open={isOpen} onClose={handleClose} className="border-none">
			<Paper className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] border-2 border-solid border-black shadow-md p-1">
				<Box className="flex flex-col gap-5 items-center">
					<TextField
						label="Params Value"
						name="param_value"
						value={downlink.param_value}
						onChange={handleChange}
						fullWidth
						id="outlined-basic"
						variant="filled"
					/>
					<button
						className="button customButtomOverAll ml-0"
						onClick={handleSchedule}
						style={{ marginLeft: 0 }}
						disabled={
							loading ? loading : loading1 ? loading1 : false
						}>
						{loading1 ? (
							<CircularProgress />
						) : (
							<Headings>Send Message</Headings>
						)}
					</button>
				</Box>
			</Paper>
		</Modal>
	);
};

const getRestriction = (value) => {
	switch (value) {
		case 0:
			return {
				min: 0,
				max: 32000,
			};
		case 1:
			return {
				min: 10,
				max: 86400,
			};
		case 2:
			return {
				min: 30,
				max: 65535,
			};
		case 3:
			return {
				min: 1,
				max: 1440,
			};
		case 4:
			return {
				min: 0,
				max: 1,
			};
		default:
			return "Unknown";
	}
};

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "rgba(235, 67, 53, 1)",
			background: "rgba(235, 67, 53, 0.1)",
		};
	} else {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	}
};
