import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import { mainStatusIndicator } from "../../Screens/Asset/StatusIndicators";

export const DoorCard = (props) => {
	const {
	  last_inspection,
	  due_inspection,
	  week_Utilisation,
	  lifetime_Utilisation,
	  report_interval,
	  open_interval,
	  description,
	  direction,
	  doorType,
	  sensors,
	  weekDoorWeekData
	} = props;
  
	const mainStatus = mainStatusIndicator({ sensors });
  
	return (
	  <div className="doorCard">
		<div className="doorCardUpper">
		  {direction == "CW" && (
			<DoorFrontOutlinedIcon className="text-white" />
		  )}
		  {direction == "CCW" && (
			<DoorBackOutlinedIcon className="text-white" />
		  )}
		</div>
		<div className="doorCardDown">
		  <div className="doorCardDownUp">
			<h6 className="text-white text-xl font-medium">
			  {description}
			</h6>
			<h6 className="text-white text-lg font-medium">
			  {doorType}
			</h6>
		  </div>
		  <div
			className="flex justify-center items-center h-8 py-2 w-full rounded-s"
			style={{
			  backgroundColor: mainStatus.background,
			}}>
			<Headings
			  className="font-futura text-base font-extralight capitalize"
			  color={mainStatus.color}>
			  {mainStatus.status}
			</Headings>
		  </div>
		</div>
	  </div>
	);
  };
